<template>
  <div class="bluebgdash px-5 mt-5 pt-1">
    <v-row class="white radius-5 mt-3 mb-2" v-for="(item, i) in items" :key="i">
      <v-col cols="12">
        <div class="d-flex align-center justify-space-between w-100">
          <div class="d-flex align-center w-100 justify-space-between">
            <img
              class="object-cover circle"
              width="50px"
              height="50px"
              :src="require('@/assets/images/register-bg.png')"
              alt=""
            />

            <div class="ml-2 w-100">
              <p class="font-16 mb-0">
                {{ item.title }}
              </p>
            </div>
            <!-- <v-btn color="#96D4C9" dark elevation="0" width="130px" outlined>
              <span class="font-16">{{
                item.is_read ? "Readed" : "Not Readed"
              }}</span>
            </v-btn> -->
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="py-1">
        <p class="font-15 mb-2">
          {{ item.message }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
  }),
  methods: {},
};
</script>

<style lang="scss"></style>
