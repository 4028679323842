<template>
  <div class="dashboard-notifications">
    <header-table
      v-if="!isMobile"
      :title="$t('dashboard.allNotifications')"
      @updateDate="items = $event.notifications"
      :url="
        userType == 'doctor'
          ? 'notifications/search'
          : 'hospital/notifications/search'
      "
    ></header-table>

    <header-table-mobile
      v-if="isMobile"
      :title="$t('dashboard.allNotifications')"
      :isSort="true"
      @searchClicked="searchClickedHandler"
      @updateDate="items = $event.notifications"
      :url="
        userType == 'doctor'
          ? 'notifications/search'
          : 'hospital/notifications/search'
      "
    ></header-table-mobile>

    <v-data-table
      v-if="!isMobile"
      :headers="headers"
      :items="items"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      @page-count="pageCount = $event"
    >
      <!-- name & image -->
      <template v-slot:item.name>
        <div class="d-flex align-center pl-3">
          <img
            class="object-contine circle"
            width="50px"
            height="50px"
            :src="require('@/assets/images/logo-mini.png')"
          />

        </div>
      </template>

      <!-- mssage -->
      <template v-slot:item.title="{ item }">
        <p class="font-16 mb-0">
          {{ item.title }}
        </p>
      </template>

    </v-data-table>

    <mobile-view @goTo="goTo" v-if="isMobile" :items="items"></mobile-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderTable from "../../components/HeaderTable.vue";
import HeaderTableMobile from "../../components/HeaderTableMobile.vue";
import MobileView from "./MobileView";
export default {
  components: { HeaderTable, HeaderTableMobile, MobileView },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    items: [],
    headers: [
      {
        text: "",
        sortable: false,
        value: "name",
      },
      { text: "", value: "title", sortable: false },
      { text: "", value: "read", sortable: false },
    ],
  }),
  computed: {
    ...mapGetters(["userType"]),
  },
  methods: {
    async getData() {
      let url;
      if (localStorage.getItem("role") == "doctor") {
        url = "notifications";
      } else {
        url = "hospital/notifications";
      }
      let { data } = await this.$axios.get(url);
      if (data.success) {
        this.items = data.data.notifications;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss">
.dashboard-notifications {
  border-radius: 15px;
  background: white;
  padding: 8px;
  tbody tr:nth-child(even) {
    background-color: $bluebgdash;
  }
  thead {
    display: none;
  }
  td,
  th {
    border-bottom: none !important;
  }
  tr {
    height: 80px;
  }
  tr:hover {
    background-color: transparent !important;
  }
  @media (max-width: 960px) {
    padding: 0px;
  }
}
</style>
